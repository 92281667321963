import { defer, iif, throwError, timer, concatMap, retryWhen, tap } from 'rxjs';
import { exponentialBackoffDelay, getDelay } from '../utils';
export function retryBackoff(config) {
  const {
    initialInterval,
    maxRetries = Infinity,
    maxInterval = Infinity,
    shouldRetry = () => true,
    resetOnSuccess = false,
    backoffDelay = exponentialBackoffDelay
  } = typeof config === 'number' ? {
    initialInterval: config
  } : config;
  return source => defer(() => {
    let index = 0;
    return source.pipe(retryWhen(errors => errors.pipe(concatMap(error => {
      const attempt = index++;
      return iif(() => attempt < maxRetries && shouldRetry(error), timer(getDelay(backoffDelay(attempt, initialInterval), maxInterval)), throwError(error));
    }))), tap(() => {
      if (resetOnSuccess) {
        index = 0;
      }
    }));
  });
}